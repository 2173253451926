.w-65 {
    width: 65% !important;
}

.logo-img {
    width: 80%;
    max-width: 200px!important;
}

.wider-modal {
    width: 80%!important;
    max-width: unset!important;
}

.page-loading {
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loading-spacer {
    display: inline-block;
    margin-right: 5px;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.main-inner-vh {
    min-height: calc(100vh - 115px) !important;
}

.fmxw-900 {
    max-width: 900px !important;
}

.fmxw-720 {
    max-width: 720px !important;
}

.team-left {
    width: calc(100% - 184px);
}

.team-right {
    width: 184px;
}

.welcome-page {
    .header {
        font-weight: 800;
    }
    p {
        opacity: 0.8;
        font-size: 1.125rem;
    }
    h3 {
        font-weight: 700;
    }
    .apps-section {
        width: 85%;
        margin: 0 auto;
        .img-holder {
            text-align: right;
        }
        .app-icon {
            &.app-icon-left {
                margin-right: 20px;
            }
        }
    }
}

.input-group {
    .PhoneInput {
        width: 100%;
        .PhoneInputCountry {
            display: flex;
            align-items: center;
            padding: 0.5rem 0.625rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            color: #9CA3AF;
            text-align: center;
            white-space: nowrap;
            background-color: #ffffff;
            border: 0.0625rem solid #D1D5DB;
            border-radius: 0.5rem;
            margin-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .PhoneInputInput {
            display: block;
            width: 100%;
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            color: #6B7280;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 0.0625rem solid #D1D5DB;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 0.5rem;
            box-shadow: 0 1px 2px 0 rgb(0 0 0 / 7%);
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            margin-left: -0.0625rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:focus {
                color: #6B7280;
                background-color: #ffffff;
                border-color: #566190;
                outline: 0!important;
                box-shadow: 0 1px 2px 0 rgb(0 0 0 / 7%), 0 0 0 0.18rem rgb(38 43 64 / 25%);
                z-index: 4;
            }
            &:focus-visible {
                border-color: #D1D5DB;
                outline: #D1D5DB auto 1px
            }
        }
    }
}

label {
    &.text-danger {
        &.d-block {
            font-size: 13px;
        }
    }
}

.login-section {
    .col-right {
        .btn-google-signin {
            border: 0.0625rem solid #D1D5DB;
            display: block;
            width: 100%;
            font-weight: bold;
            color: #696974;
            img {
                width: 22px;
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
    .col-left {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        h2 {
            z-index: 2;
        }
        &::after {
            height: 50%;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(0, 0, 0);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4433123591233369) 54%, rgba(0, 0, 0, 0.8018557764902836) 100%);
            z-index: 1;
        }
    }
}

.sidebar-inner::-webkit-scrollbar {
    display: none;
}

.sidebar-inner {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@media (max-width: 768px) {
    .col-right {
        width: 100%!important;
    }
    .col-left {
        width: 100%;
        h2 {
            text-align: center;
            width: 100%;
        }
    }
}

@media (max-width: 550px) {
    .col-right {
        .bg-white {
            &.fmxw-500 {
                width: 90%!important;
            }
        }
    }
}